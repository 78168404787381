<template>
  <div>
    <Breadcrumbs title="Student Payment Report" main="Reports"/>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">

          <div class="card">
            <div class="card-body">

              <b-row>
                <b-col cols="12" md="4">
                  <b-form-group label="Institute">
                    <b-form-select @change="fetchTeachers" v-model="formSearch.institute_id" :options="institutes" value-field="id" text-field="text" class="btn-square border"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-group label="Teacher">
                    <b-form-select @change="fetchClasses" v-model="formSearch.teacher_id" :options="teachers" value-field="id" text-field="text" class="btn-square border"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-group label="Cashier">
                    <b-form-select v-model="formSearch.cashier_id" :options="cashiers" value-field="id" text-field="text" class="btn-square border"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-group label="Start Date *">
                    <b-form-datepicker reset-button close-button v-model="formSearch.start_date"></b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-group label="End Date *">
                    <b-form-datepicker reset-button close-button v-model="formSearch.end_date" class="btn-square border"></b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-group label="Class">
                    <b-form-select v-model="formSearch.class_detail_id" :options="class_details" value-field="id" text-field="text" class="btn-square border" required></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col v-show="false" cols="12" md="2">
                  <b-form-group label="Year">
                    <b-form-input v-model="formSearch.year"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-group label="Student NIC">
                    <b-form-input v-model="formSearch.student_nic"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" class="text-center">
                  <b-button @click="generateReport" :disabled="!formSearch.start_date || loading" size="sm" class="btn btn-square text-uppercase btn-primary m-2">Generate</b-button>
                  <b-button @click="printReport('print')" :disabled="!totalRows || loading" size="sm" class="btn btn-square text-uppercase btn-success m-2">Print</b-button>
                </b-col>
              </b-row>

            </div>
          </div>

          <div class="card">
            <div class="card-body printing" id="print">


              <div class="text-center">
                <h2 class="card-title">Student Payment Report</h2>
              </div>
              <b-row>
                <b-col cols="4" md="3" lg="2">Institute</b-col>
                <b-col cols="8" md="9" class="text-left">: {{ institutes.filter(record => record.id===formSearch.institute_id).map(record => record.text)[0] }}</b-col>
              </b-row>
              <b-row>
                <b-col cols="4" md="3" lg="2">Lecturer</b-col>
                <b-col cols="8" md="9" class="text-left">: {{ teachers.filter(record => record.id===formSearch.teacher_id).map(record => record.text)[0] }}</b-col>
              </b-row>
              <b-row v-show="formSearch.subject_id">
                <b-col cols="4" md="3" lg="2">Class</b-col>
                <b-col cols="8" md="9" class="text-left">: {{ subjects.filter(record => record.id===formSearch.subject_id).map(record => record.text)[0] }}</b-col>
              </b-row>
              <b-row>
                <b-col cols="4" md="3" lg="2">Cashier</b-col>
                <b-col cols="8" md="9" class="text-left">: {{ cashiers.filter(record => record.id===formSearch.cashier_id).map(record => record.text)[0] }}</b-col>
              </b-row>
              <b-row>
                <b-col cols="4" md="3" lg="2">Class</b-col>
                <b-col cols="8" md="9" class="text-left">: {{ class_details.filter(record => record.id===formSearch.class_detail_id).map(record => record.text)[0] }}</b-col>
              </b-row>
              <b-row>
                <b-col cols="4" md="3" lg="2">Payment Period</b-col>
                <b-col cols="8" md="9">: {{formSearch.start_date || "N/A"}} to {{formSearch.end_date || "N/A"}}</b-col>
              </b-row>

              <div class="table-responsive datatable-vue" v-show="!loading">
                <b-table
                    ref="table"
                    show-empty
                    selected-variant="success"
                    :items="tableRecords"
                    :fields="tableFields"
                    :filter="filter"
                    :current-page="1"
                    :per-page="perPage"
                    @row-selected="rowSelected"
                >

                  <template #cell(index)="row">
                    {{ (1+row.index) }}
                  </template>
                  <template #cell(nic_name)="row">
                    <p class="m-0">{{row.item.user.first_name}} {{row.item.user.last_name}}</p>
                    <p class="m-0">{{row.item.user.nic}}</p>
                  </template>
                  <template #cell(class_detail)="row">
                    <p class="m-0">{{row.item.class_detail.name}}</p>
                    <p class="m-0">{{row.item.institute.name}}</p>
                  </template>
                  <template #cell(month)="row">
                    {{ moment(row.item.payment_month).format('YYYY-MM') }}
                  </template>
                  <template #cell(pay_date_time)="row">
                    {{ moment(row.item.created_at).format('llll') }}
                  </template>
                  <template #cell(cashier)="row">
                    {{ row.item.creator.first_name }} {{ row.item.creator.last_name }}
                  </template>
                  <template #cell(settled)="row">
                    <span v-if="row.item.settlement_id" class="text-success">YES</span>
                    <span v-else class="text-error">NO</span>
                  </template>

                  <template #cell(action)="row" class="text-right">
                    <b-button v-show="checkUserPermissions(user,['update_class_schedules'])" class="btn-square mr-1 ml-1" variant="success" @click="showEditModal(row.index, row.item, $event.target)">Edit</b-button>
                    <b-button v-show="checkUserPermissions(user,['delete_class_schedules'])" class="btn-square" variant="danger" @click="showDeleteModal(row.index, row.item, $event.target)">Remove</b-button>
                  </template>
                </b-table>
              </div>


              <b-row>
                <b-col cols="4" md="3" lg="2">Discounts</b-col>
                <b-col cols="8" md="9" class="text-left">: Rs. {{ discount_total }}</b-col>
              </b-row>
              <b-row>
                <b-col cols="4" md="3" lg="2">Institute Share</b-col>
                <b-col cols="8" md="9" class="text-left">: Rs. {{ institute_share }}</b-col>
              </b-row>
              <b-row>
                <b-col cols="4" md="3" lg="2">Teacher Share</b-col>
                <b-col cols="8" md="9" class="text-left">: Rs. {{ teacher_share }}</b-col>
              </b-row>
              <b-row>
                <b-col cols="4" md="3" lg="2">Total</b-col>
                <b-col cols="8" md="9" class="text-left">: Rs. {{ report_total }}</b-col>
              </b-row>

            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {checkUserPermissions} from "@/plugins/functions";
import API from '@/services/api'
import { Printd } from 'printd'
export default {
  name: "student_payments_report",
  computed: mapState({ user: state => JSON.parse(state.auth.user) }),
  data() {
    return {
      api_base: '/api/backend/reports/',
      filter: '',
      totalRows: 0,
      perPage: 100,
      lastPage: 1,
      currentPage: 1,
      tableRecords: [],
      loading: false,


      tableFields: [
        { key: 'index', label: '#', sortable: false, },
        { key: 'nic_name', label: 'NIC / Name', sortable: true, },
        { key: 'class_detail', label: 'Class Details', sortable: true, },
        { key: 'month', label: 'Month', sortable: true, },
        { key: 'pay_date_time', label: 'Date/Time', sortable: true, },
        { key: 'class_fee', label: 'Class Fee', sortable: false, },
        { key: 'discount', label: 'Discount', sortable: false, class: 'text-right' },
        { key: 'amount', label: 'Paid Amount', sortable: false, },
        { key: 'cashier', label: 'Cashier', sortable: false, },
        { key: 'payment_type.name', label: 'Payment Method', sortable: false, },
        { key: 'settled', label: 'Settled', sortable: false, },
        // { key: 'action', label: 'Action', sortable: false, class: 'text-right text-capitalize' }
      ],

      report_total: 0,
      institute_share: 0,
      teacher_share: 0,
      discount_total: 0,
      lecturer_name: 'N/A',
      summaryTableFields: [
        { key: 'id', label: "", class: 'text-left' },
        { key: 'value', label: "", class: 'text-right' },
      ],
      summaryTableRecords: [
        { id: "Total: ", value: 0 },
        { id: "Institute Share: ", value: 0 },
        { id: "Teacher Share: ", value: 0 },
      ],

      institutes: [],
      subjects: [],
      teachers: [],
      cashiers: [],
      class_details: [],
      formSearch: {
        start_date:'',
        end_date: '',
        institute_id: null,
        cashier_id: null,
        teacher_id: null,
        subject_id: null,
        class_detail_id: null,
        student_nic: '',
        year: new Date().getFullYear()
      },

    }
  },
  mounted() {
    this.fetchInstitutes()
    this.fetchCashiers()
    this.fetchTeachers()
  },
  watch: {
    lecturer_name()  {
      if (this.formSearch.teacher_id === null) {
        return "N/A"
      }
      return this.teachers.filter(user => user.id===this.formSearch.teacher_id).map(user => user.text)[0]
    },
  },
  methods: {
    checkUserPermissions,
    rowSelected(item) { this.selected = item },
    // showMoney(value){ return value.toLocaleString('en-US', {style: 'currency', currency: 'LKR'}) },
    async fetchInstitutes() {
      this.institutes = []
      await API.select('institutes', {
        status: 0
      }).then((result) => {
        if(result.data.data.length) {
          this.institutes = result.data.data
        }
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        this.institutes.unshift({
          id: null, text: "All Institutes"
        })
        this.fetchClasses()
      })
    },
    async fetchCashiers() {
      this.cashiers = []
      await API.select('cashiers', {
        status: 0
      }).then((result) => {
        if(result.data.data.length) {
          this.cashiers = result.data.data
        }
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        this.cashiers.unshift({
          id: null, text: "All Cashiers"
        })
        this.fetchClasses()
      })
    },
    async fetchTeachers() {
      this.teachers = []
      await API.select('teachers', {
        institute_id: this.formSearch.institute_id
      }).then((result) => {
        this.teachers = result.data.data
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        this.teachers.unshift({
          id: null, text: "All Teachers"
        })
        this.fetchClasses()
      })
    },
    async fetchSubjects() {
      this.subjects = []
      // await API.select('subjects', {
      //   teacher_id: this.formSearch.teacher_id
      // }).then((result) => {
      //   this.subjects = result.data.data
      // }).catch((error) => {
      //   console.log(error)
      // }).finally(() => {
        this.subjects.unshift({
          id: null, text: "All Subjects"
        })
      // })
    },
    async fetchClasses() {
      this.class_details = []
      await API.select('classes', {
        institute_id: this.formSearch.institute_id,
        teacher_id: this.formSearch.teacher_id,
      }).then(result => {
        this.class_details = result.data.data
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.class_details.unshift({
          id: null, text: "All Classes"
        })
      })
    },
    async fetchPayments(){
      await API.post('/api/backend/reports/student-payments/data', {
        institute_id: this.formSearch.institute_id,
        teacher_id: this.formSearch.teacher_id,
        cashier_id: this.formSearch.cashier_id,
        start_date: this.formSearch.start_date,
        end_date: this.formSearch.end_date,
        student_nic: this.formSearch.student_nic,
        class_detail_id: this.formSearch.class_detail_id,
        page: this.currentPage,
        per_page: 500,
        status: 0,
      }).then(result => {
        this.lastPage = result.data.data.last_page
        this.perPage = result.data.data.total
        this.totalRows = result.data.data.total
        this.tableRecords = this.tableRecords.concat(result.data.data.data)
      }).catch(error => {
        console.log(error)
        this.$toasted.error(error.message)
      })
    },
    async generateReport() {
      this.tableRecords = []
      this.currentPage = 0
      this.report_total = 0
      this.totalRows = 0
      this.institute_share = 0
      this.teacher_share = 0
      this.discount_total = 0
      this.loading = true

      // // fetch total record count
      // // fetch record in loop of 500 records
      await API.post('/api/backend/reports/student-payments/summary', {
        institute_id: this.formSearch.institute_id,
        teacher_id: this.formSearch.teacher_id,
        cashier_id: this.formSearch.cashier_id,
        start_date: this.formSearch.start_date,
        end_date: this.formSearch.end_date,
        student_nic: this.formSearch.student_nic,
        class_detail_id: this.formSearch.class_detail_id
      }).then(result => {
        this.totalRows = result.data.data.records
        this.report_total = result.data.data.report_total
        this.institute_share = result.data.data.institute_share
        this.teacher_share = result.data.data.teacher_share
        this.discount_total = result.data.data.discount_total
        this.summaryTableRecords = [
          { id: "Total: ", value: this.report_total },
          { id: "Institute Share: ", value: this.institute_share },
          { id: "Teacher Share: ", value: this.teacher_share },
        ]
      }).catch(error => {
        console.log(error)
        this.loading = false
        this.$toasted.error(error.message)
      })

      // Fetch payment records looped
      await clearTimeout()
      await this.fetchPayments()
      await setInterval(() => {
        if(this.currentPage <= this.lastPage) {
          this.currentPage += 1
          this.fetchPayments()
        } else {
          clearTimeout()
          this.loading = false
        }
      }, 500)

    },
    printReport(el) {
      const d = new Printd()
      d.print( document.getElementById(el), [
          `
          * { font-family: serif; font-size: 12px; },
          @page { margin: 2.5cm; }
          div.row > div { display: inline-block; }
          div.row { display: block; }
          h2 { font-size: 18px; text-align:center; }
          `
      ])
    },

    showEditModal(){},
    showDeleteModal() {},
  }
}
</script>

<style scoped>
@media print {
  .table {
    border: 1px solid #0c0e13;
  }
}
</style>
